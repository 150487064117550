body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

nav a.active {
  border-bottom: 5px solid #333;
  transition: all ease 0.5s;
}
